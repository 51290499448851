import React, { Component } from 'react';
class Work extends Component {
    render() { 
        return (  
            <div  className="project-page" id="project" >
                <div className="project_block">
             <div className="project-block__content">
            <div className="project__title">
                <h2 className="title__text">Coming Soon</h2>
            </div>
       {/* <div className="project__num">
            <p className="page_num">01</p>
        </div> */} 
        </div>
        </div></div>
        );
    }
}
 
export default Work;
import * as types from "../actiontype";

export const onloader = () => {
    return {
      type: types.SET_SHOWNINTRO
    }
  } 
 export const menuopen = () => ({
    type: types.SET_MENUOPEN
  });

  